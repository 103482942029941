<template>
  <div>
    <div class="loginAdd">
      <el-form ref="loginForm" :model="form" class="login-formAdd" label-width="120px">
        <h3 class="title">申请加盟商户</h3>
        <el-form-item label="登陆用户名：">
          <div style="display: flex">
            <el-input v-model="form.name"></el-input>
            <span class="formSpan">* 用户名称（必填）</span>
          </div>
        </el-form-item>
        <el-form-item label="密码：">
          <div style="display: flex">
            <el-input v-model="form.password" type="password"></el-input>
            <span class="formSpan">* 登录密码</span>
          </div>
        </el-form-item>
        <el-form-item label="确认密码：">
          <div style="display: flex">
            <el-input v-model="form.confirmPassword" type="password"></el-input>
            <span class="formSpan">* 重复确认密码</span>
          </div>
        </el-form-item>
        <el-form-item label="商户真实全称：">
          <div style="display: flex">
            <el-input v-model="form.name"></el-input>
            <span class="formSpan">* 公司真实全称</span>
          </div>
        </el-form-item>
        <el-form-item label="商户资质材料：">
          <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
            :limit="3"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="固定电话：">
          <div style="display: flex">
            <el-input v-model="form.phone"></el-input>
            <span class="formSpan">* 固定电话联系方式，如：010-88888888</span>
          </div>
        </el-form-item>
        <el-form-item label="手机号码：">
          <div style="display: flex">
            <el-input v-model="form.phone" type="number"></el-input>
            <span class="formSpan">* 移动电话联系方式：如：13000000000</span>
          </div>
        </el-form-item>
        <el-form-item label="邮箱：">
          <div style="display: flex">
            <el-input v-model="form.email" type="number"></el-input>
            <span class="formSpan">* 电子邮箱联系方式：如：ytash@163.com</span>
          </div>
        </el-form-item>
        <el-form-item label="地区：">
          <div class="block">
            <el-cascader
              v-model="form.city"
              :options="options"></el-cascader>
          </div>
        </el-form-item>
        <el-form-item label="详细地址：">
          <div style="display: flex">
            <el-input v-model="form.address"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="企业官网：">
          <div style="display: flex">
            <el-input v-model="form.web"></el-input>
            <span class="formSpan">填写完整的网址</span>
          </div>
        </el-form-item>
        <div class="addFormAdd">
          申请加盟
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        form:{},
        fileList:[],
        options:[{
          value:"北京市",
          label:"北京市",
          children: [{
            value: '市辖区',
            label: '市辖区',
            children: [{
              value: '东城区',
              label: '东城区'
            },{
              value: '西城区',
              label: '西城区'
            }]
          },{
            value: '县',
            label: '县',
            children: [{
              value: '密云县',
              label: '密云县'
            },{
              value: '延庆县',
              label: '延庆县'
            }]
          }]
        },{
          value:"天津市",
          label:"天津市",
          children: [{
            value: '市辖区',
            label: '市辖区',
            children: [{
              value: '和平区',
              label: '和平区'
            },{
              value: '河东区',
              label: '河东区'
            }]
          },{
            value: '市辖县',
            label: '市辖县',
            children: [{
              value: '宁河县',
              label: '宁河县'
            },{
              value: '静海县',
              label: '静海县'
            }]
          }]
        }]
      }
    },
    methods: {

    }
  }
</script>

<style lang="scss">
  .loginAdd {
    display: flex;
    overflow: auto;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-size: 21px;
  }

  .login-formAdd {
    border-radius: 6px;
    background: #ffffff;
    width: 800px;
    padding: 25px 25px 5px 25px;
    margin-top: 5%;
    margin-bottom: 5%;

    .el-input {
      height: 38px;
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .login-code-img {
    height: 38px;
  }

  .formSpan{
    width: 80%;
    margin-left: 10px;
  }

  .addFormAdd{
    width: 30%;
    margin-left:35%;
    text-align: center;
    background-color: dodgerblue;
    height: 40px;
    color: white;
    font-size: 17px;
    padding-top: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor:pointer;
  }
</style>
