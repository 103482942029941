<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" class="login-form">
      <h3 class="title">账号注册</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="填写2-20个字符的用户名，字母、数字、下划线和中文" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="填写6-32个字符" />
      </el-form-item>
      <el-form-item prop="password1">
        <el-input v-model="loginForm.password1" type="password" auto-complete="off" placeholder="重复上面所填写的密码" />
      </el-form-item>
      <el-form-item prop="code">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input v-model="loginForm.code" type="code" auto-complete="off" placeholder="手机验证码"/>
          </el-col>
          <el-col :span="8">
            <el-tag type="success" effect="plain" size="medium">NEAEF</el-tag>
          </el-col>
        </el-row>
      </el-form-item>
      <div style="display: flex">
        <el-checkbox v-model="loginForm.rememberMe" style="margin: 0px 0px 25px 0px">
          我已阅读并同意
        </el-checkbox>
        <span style="margin-top: -2px;cursor: pointer;" @click="toService()">《服务协议》</span>
      </div>
      <el-form-item style="width: 100%">
        <el-button size="medium" type="primary" style="width: 100%">
          <span>现在注册</span>
        </el-button>
      </el-form-item>
      <div style="display: flex">
        <div class="loginDiv">已有账号？<span class="loginSpan" @click="toLogin()">立即登录</span></div>
      </div>
    </el-form>
  </div>

</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        loginForm:{},
        type:false
      }
    },
    methods: {
      //跳转登录页
      toLogin(){
        this.$emit('changeType',2)
      },
      toService(){
        this.$emit('changeType1','1-1',10)
      }
    }
  }
</script>

<style lang="scss">
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("../../../assets/loginBg.jpg");
    background-size: cover;
  }

  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-size: 21px;
  }

  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 400px;
    height: 450px;
    padding: 25px 25px 5px 25px;
    margin-left: 50%;
    margin-top: 5%;
    margin-bottom: 5%;

    .el-input {
      height: 38px;
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code-img {
    height: 38px;
  }

  .loginDiv{
    font-size: 14px;
    margin-left: 10px;
    margin-top: -2px;
  }

</style>
