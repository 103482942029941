<template>
  <div>
    <div class="backgroundInfo"></div>
    <div style="width: 90%;margin-left: 5%">
      <el-row style="width: 100%">
        <el-col :span="4">
          <el-menu
            @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="1" @click="changeType('1')">
              <i class="el-icon-s-promotion"></i>
              <span slot="title">心脑血管</span>
            </el-menu-item>
            <el-menu-item index="2" @click="changeType('2')">
              <i class="el-icon-s-promotion"></i>
              <span slot="title">皮肤用药</span>
            </el-menu-item>
            <el-menu-item index="3" @click="changeType('3')">
              <i class="el-icon-s-promotion"></i>
              <span slot="title">肝胆胰类</span>
            </el-menu-item>
            <el-menu-item index="4" @click="changeType('4')">
              <i class="el-icon-edit"></i>
              <span slot="title">发布信息</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="20" style="margin-bottom: 20px">
          <div v-show="index === '1'">
            <div class="titleDivInfo">
              信息服务
            </div>
            <div v-for="item in list[0]" @click="changeType('detail')" style="cursor: pointer">
              <div class="listTitle">
                {{item.title}}
              </div>
              <div class="listContent">
                {{item.content}}
              </div>
            </div>
          </div>
          <div v-show="index === '2'">
            <div class="titleDivInfo">
              信息服务
            </div>
            <div v-for="item in list[1]" @click="changeType('detail')" style="cursor: pointer">
              <div class="listTitle">
                {{item.title}}
              </div>
              <div class="listContent">
                {{item.content}}
              </div>
            </div>
          </div>
          <div v-show="index === '3'">
            <div class="titleDivInfo">
              信息服务
            </div>
            <div v-for="item in list[2]" @click="changeType('detail')" style="cursor: pointer">
              <div class="listTitle">
                {{item.title}}
              </div>
              <div class="listContent">
                {{item.content}}
              </div>
            </div>
          </div>
          <div v-show="index === '4'">
            <div>
              <el-form ref="loginForm" :model="form" class="login-formInfo" label-width="120px">
                <h3 class="title">发布信息</h3>
                <el-form-item label="联系人：">
                    <el-input v-model="form.name" placeholder="填写联系人姓名"></el-input>
                </el-form-item>
                <el-form-item label="联系方式：">
                  <el-input v-model="form.content" placeholder="填写联系方式"></el-input>
                </el-form-item>
                <el-form-item label="标题：">
                  <el-input v-model="form.title" placeholder="填写标题"></el-input>
                </el-form-item>
                <el-form-item label="选择分类：">
                  <el-select v-model="form.type" placeholder="请选择分类">
                    <el-option label="供应信息" value="供应信息"></el-option>
                    <el-option label="求购信息" value="求购信息"></el-option>
                    <el-option label="展会信息" value="展会信息"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="价格：">
                  <el-input v-model="form.price" placeholder="填写价格" type="number"></el-input>
                </el-form-item>
                <el-form-item label="图片：">
                  <el-upload
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    multiple
                    :limit="3"
                    :file-list="fileList">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="介绍：">
                  <el-input v-model="form.introduce" placeholder="填写介绍"></el-input>
                </el-form-item>
                <el-form-item label="详情：">
                  <el-input v-model="form.text" placeholder="填写介绍" type="textarea"></el-input>
                </el-form-item>
                <div class="addForm">
                  发布
                </div>
              </el-form>
            </div>
          </div>
          <div v-show="index === 'detail'">
            <div class="detailDiv">
              <div class="detailTitle">
                患上冠心病前，可能会出现这7种症状
              </div>
              <div class="detailAuth">
                发布人：张公明 15957132770 来源：个人
              </div>
              <div class="detailContext">
                以前冠心病是老年人的专利，现在越来越多的年青人也加入了这个行列，因此冠心病的早诊早治问题不容忽视。如果能做到早发现、早诊断、早治疗，可大概率减少冠心病的危害。
                <br><br>
                现代西医学认为冠心病的发生与冠状动脉血管发生动脉粥样硬化病变有关。冠心病相当于中医学上的胸痹等疾病，中医学认为其发生是因为寒凝、热胜、痰瘀互结、气滞等原因导致血瘀不通引起的问题，虽然因为理论体系不同的原因导致中西医对冠心病的发病病因和机制的认识不同，但是主要观点都围绕血液循环通畅程度展开。
                <br><br>
                有7种症状可以作为诊断冠心病的早期信号，在平时如果能注意时时观察、经常自测，就可以做到及时发现冠心病。
                <br><br>
                一、心脏周围出现的冠心病信号
                <br><br>
                1、胸部刺痛或胀痛
                <br><br>
                心前区部位经常出现刺痛、胀痛的症状，在情绪激动或喝酒、天气极寒时，胸痛的程度相应加重，或者肩背部位也一起疼痛，这时可能已经得了冠心病好久了，需要赶紧就诊。
                <br><br>
                2、胸部胀闷
                <br><br>
                心前区经常有憋闷感，但是没有出现过胸肩背疼痛的症状，也没有消化方面的疾病，这种情况一般也是冠心病引起的不适症状，这类患者的血脂也经常偏高，血液比较粘稠。
                <br><br>
                3、心悸不宁
                <br><br>
                频繁出现心悸的症状，没有心律不齐等相关的疾病史，这也可能是冠心病的一个征兆，需要及时做心血管造影等影像学检查确诊。
                <br><br>
                二、头部出现的冠心病信号
                <br><br>
                1、面色
                <br><br>
                根据中医学理论，五脏六腑在面部都有对应的分布区，如果心、肝、脾、肺、肾等脏腑发生病变，面部相应部位就会出现颜色等形态变化，心脏的位置对应于两目之间、即鼻梁骨的最低处，中医学称为名堂，如果这个位置出现青紫色，就是提示有可能已经患有冠心病，需要及时去医院就诊。
                <br><br>
                2、舌色
                <br><br>
                中医学认为心开窍于舌，一般情况下舌质颜色紫黯有瘀斑，多是血瘀不畅引起的问题，也是冠心病的一个征兆。
                <br><br>
                3、舌下络脉
                <br><br>
                根据老中医的临床经验，舌下络脉分布在舌体下面，起于金津、玉液穴，通过经络与脏腑气血直接联系。舌下络脉青紫色者，脉形粗长怒张或细短紧束，小络脉青紫或暗赤弯曲，或有小颗粒者，为气滞血瘀或夹痰瘀阻之证，常见于严重的冠心病，舌下络脉淡紫色者，脉形粗长怒张或细短紧束，小络脉淡紫或暗赤弯曲或有小颗粒者，为寒凝或阳虚血运不畅、气虚血滞之证，也是冠心病的一个特征。
                <br><br>
                三、其它部位出现的冠心病的信号
                <br><br>
                手麻肩痛：根据中医学理论，手少阴心经的循行从心系上肺，斜出于腋下的极泉穴，沿上肢的前侧行于手太阴肺经和手厥阴心包经的内侧，再下行至肘节的少海穴，沿前臂尺侧，到达手掌后豌豆骨突起处的神门穴，进入掌中，沿小指桡侧至其末端的少冲穴。
                <br><br>
                中医学又认为心主血，如果血瘀不畅，就会使经络不通，因此从手指麻木、肩臂疼痛就可以判断出有可能是心脏出了问题。
                <br><br>
                以上这七种信号可能只出现一种，也可能同时出现几种，只要平时注意观察和经常自测，就能及早发现冠心病，得了冠心病非常可怕，早诊早治可以减少危害，但是冠心病的发生大多数情况下都是不注意身体保养引起的问题，因此平时要注意保养身体，少喝酒少吸烟、少吃生冷滋腻或辛辣的食物。
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'index',
    data() {
      return {
        index:"1",
        form:{},
        fileList:[],
        list:[
          [
            {
              title: "1.患上冠心病前，可能会出现这7种症状",
              content: "以前冠心病是老年人的专利，现在越来越多的年青人也加入了这个行列，因此冠心病的早诊早治问题不容忽视。如果能做到早发现、早诊断、早治疗，可大概率减少冠心病的危害。"
            },
            {
              title: "2.如果高血压一直不稳，可能是细节问题",
              content: "导致高血压的主要原因是血管老化问题，而血管老化主要与年龄增长有关，此外，日常饮食和生活习惯不良也会导致血管提前老化。"
            },
            {
              title: "3.高血脂出现的4个症状",
              content: "有很多疾病都是隐形的，一般都发现不了，等到发现的时候就已经比较严重了，高血脂就是这一类疾病。很多像急性心肌梗死、冠心病等一些心血管方面的疾病就是因为血脂过高引起的。"
            },
            {
              title: "4.这5种高血压，更容易发生脑中风",
              content: "高血压最担心的就是发展为中风，中风包括出血性和缺血性，也就是脑出血和脑梗死"
            },
            {
              title: "5.高血压患者的秋冬饮食策略",
              content: "高血压患者秋冬该怎么吃？秋冬气温下降，人体需要更多的热量应付日常生活、工作的能量消耗，进食量也会增加。对于高血压患者来说，秋冬饮食既要补充足够的能量和营养，又要注意控制血压水平，那么秋冬高血压患者应该怎样吃呢？"
            },
            {
              title: "6.多吃这些水果有助降血压",
              content: "高血压吃什么好？高血压的调理离不开饮食，合理饮食有助控制血压水平，不少水果也有很好的降压作用，还能保护心脑血管，高血压患者不妨多吃以下这些养护血管的水果哦！"
            }
          ],
          [
            {
              title: "1.烫伤严重还是烧伤严重 教你如何区分",
              content: "烫伤和烧伤是日常生活中比较常见，发生频率较高的意外伤害，但我们对这两者的区别和等级划分的了解却不是很充足。"
            },
            {
              title: "2.身上长湿疹了，做好这5点很重要",
              content: "湿疹是由多种复杂的内外因素引起的皮肤反应，外部因素：化妆品、香料、清洁剂、蛋类、鱼虾、日晒等；体内因素：过敏性体质，代谢、过度疲劳，精神紧张，多汗，皮肤干燥等。"
            },
            {
              title: "3.皮肤湿疹的症状有哪些",
              content: "湿疹是一种皮肤变态反应，在临床当中主要可以分为急性湿疹和亚急性湿疹、慢性湿疹三种类型，急性湿疹的患者皮肤会出现很多米粒大小的丘疹，伴随着瘙痒的症状，而慢性湿疹就是急性湿疹或者亚急性湿疹转变而来的。"
            },
            {
              title: "4.皮肤瘙痒预示这6种疾病v",
              content: "每当冬天到来的时候，天气都变得特别的干燥，很多人都会出现皮肤方面的问题。因为在冬天天气变得比较干，皮肤缺水就会引起皮肤瘙痒或者是其他皮肤疾病。"
            }
          ],
          [
            {
              title: "1.乙肝可以治愈吗？什么情况下可以停药？",
              content: "乙肝是一种传染性疾病，虽然有了乙肝疫苗可以预防大范围的传染，但是发病率还是非常高，据数据显示，全球慢性乙肝患者高达2.57亿，每年有88.7万人因感染乙肝病毒而死亡。"
            },
            {
              title: "2.家里有乙肝患者，有些地方要警惕",
              content: "我国的乙肝发病人数众多，属于乙肝大国。可能很多人都听过呀，肝病三部曲乙肝就是这三部曲的第一步，接下来就是肝硬化，最终有可能会发展成为肝癌，因此，乙肝对患者的身体健康有非常严重的威胁。"
            },
            {
              title: "3.查肝胆胰腺需要空腹吗",
              content: "肝胆胰腺都是消化系统中非常重要的器官，如果他它们出现了问题，不然会严重的影响消化功能，而且还会给患者造成非常大的痛苦。"
            },
            {
              title: "4.脂肪肝的表现",
              content: "随着社会的发展，人们的生活水平得到了很大的提升，同时可供人们食用的食物也越来越多。"
            },
            {
              title: "5.肝不好皮肤有提示",
              content: "人体最大的器官是什么？答案是：皮肤！"
            }
          ]
        ]
      }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath)
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath)
      },
      //切换显示
      changeType(type){
        this.index = type
      }
    }
  }
</script>

<style lang="scss">
  .backgroundInfo{
    height: 300px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-image: url("../../../assets/info_bg.png");
  }

  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }

  .titleDivInfo{
    width: 90%;
    background-color: #F4F4F4;
    font-size: 20px;
    height: 50px;
    margin-left: 20px;
    padding-left: 30px;
    padding-top: 10px;
    border-left: 4px solid #3A3C84;
  }

  .listTitle{
    margin-left: 30px;
    font-size: 14px;
    color: #555555;
    margin-top: 15px;
  }

  .listContent{
    margin-left: 30px;
    font-size: 12px;
    color: #CCCCCC;
    margin-top: 10px;
    padding-bottom: 20px;
    width: 90%;
    border-bottom: 1px solid #F4F4F4;
  }

  .title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #707070;
    font-size: 21px;
  }

  .login-formInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 800px;
    padding: 25px 25px 5px 25px;
    margin-top: 5%;
    margin-bottom: 5%;

    .el-input {
      height: 38px;
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .addForm{
    width: 30%;
    margin-left:35%;
    text-align: center;
    background-color: dodgerblue;
    height: 40px;
    color: white;
    font-size: 17px;
    padding-top: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor:pointer;
  }

  .detailDiv{
    width: 90%;
    margin-left: 5%;
  }

  .detailTitle{
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    color: #191919;
  }

  .detailAuth{
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    border-bottom: 1px solid #F4F4F4;
    color: #3A74B8;
    padding-bottom: 30px;
  }

  .detailContext{
    font-size: 14px;
    margin-top:20px;
    color: #555555;
    margin-bottom: 20px;
  }

</style>
