<template >
	   <div id="app" style="width:90%;margin: 30px;margin-left: 80px;">

     <el-card class="box-card">
                <!-- 购物车商品 -->
                    <el-table :data="addedGoods" @select="handleSum" @select-all="handleSum" ref="multipleTable" >
                        <!-- 复选框 -->
                        <el-table-column type="selection" width="55"></el-table-column>
                           <el-table-column prop="image" label="图片" width="180" >
                            <template v-slot="img">
                              <img  :src="img.row.leftImg"  style="width:100px; height:100px"/>
                              </template>
                           </el-table-column>
                        <el-table-column prop="name" label="名称" width="180"   align="center"></el-table-column>
                        <el-table-column label="赠送积分" width="280"   align="center">
                            <!-- 使用计数器来添加数量，绑定表格数据行对应的count -->
                            <template>
                               0
                            </template>
                        </el-table-column>
                        <el-table-column prop="price" label="单价"   align="center">
                            <template v-slot="prices">
                                <span style="font-weight:800;color:red">￥{{prices.row.price}}</span>
                            </template>
                        </el-table-column>

                          <el-table-column label="优惠"  align="center">
                            <template>
                                减<span>￥ 0</span>
                            </template>
                        </el-table-column>
                           <el-table-column label="数量" width="280"   align="center">
                            <!-- 使用计数器来添加数量，绑定表格数据行对应的count -->
                            <template v-slot="scope">
                                <el-input-number v-model="scope.row.count" @change="handleSum" :min="1" :max="10"></el-input-number>
                            </template>
                        </el-table-column>
                          <el-table-column label="金额" width="180"   align="center">
                            <template v-slot="money">
                                <span style="font-weight:800;color:red">￥{{money.row.price*money.row.count}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100" align="center">
                            <template v-slot="scope">
                                <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="height: 100px">
                    <div style="margin: 20px;float: right;text-align: right">
                       <div> 商品总重量：<span style="color: red;">0g</span></div>
                       <div style="margin-top: 5px;"> 商品总金额：￥2235.00 - 商品优惠：￥0.00 - 促销活动优惠：<span style="color: red;">￥0.00</span></div>
                       <div style="margin-top: 5px;font-weight: 500;"> 金额总计（不含运费）：<span style="color: red;">￥{{sum}}</span></div>
                    </div>
                    </div>
</el-card>
                    <div style="margin-top: 15px;">
                      <el-row >
                        <el-col :span="20">
                            <el-button  style="float:left" icon="el-icon-close" @click="clearCart" plain>清空购物车</el-button>
                          </el-col>

   <el-col :span="2">
                            <el-button style="float:right" icon="el-icon-refresh-left" @click="shopping" plain>继续购物</el-button>
                          </el-col>

                             <el-col :span="2">
                            <el-button type="primary" style="float:right" icon="el-icon-postcard">去结算</el-button>
                          </el-col>
                        </el-row>
                      </div>
                      <br/>
    </div>

</template>

<script>
export default {

      props:{
        detail:{
          type:String
        }
    },
	data() {
		return {
  sum: 0,
                activeName: 'first',
                // 添加进购物车的商品数组
                addedGoods: [{
                    name: '	易孚 重组人表皮生长因子凝胶 20g/支',
                    price: 149,
                    leftImg:"http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDUwMzMxNzc1LmpwZy93LzY2L2gvNjYO0O0O",
                    count: 1
                }, ],
                // 设置的商品
                goods: [{
                    name: '手机',
                    price: 1000,
                    count: 1
                }, {
                    name: '电脑',
                    price: 2000,
                    count: 1
                }, {
                    name: '平板',
                    price: 3000,
                    count: 1
                }, {
                    name: '电视',
                    price: 4000,
                    count: 1
                }, {
                    name: '空调',
                    price: 5000,
                    count: 1
                }]

    }
	},

     mounted(){

    },
    computed:{
        application(){
            return this.detail
        }
    },
    watch: {
        application: function (val) {
            console.log(val)
               this.addedGoods = [];
          this.addedGoods.push(this.detail);

        },
    },
	methods: {

       // 计算总额
                handleSum() {
                    this.sum = 0;
                    this.$nextTick(() => {
                        // console.log(this.$refs.multipleTable.selection);
                        // this.$refs.multipleTable.selection获取被选中的行
                        this.$refs.multipleTable.selection.forEach(item => {
                            this.sum += (item.price * item.count)
                        });
                    })
                },
                // 删除操作
                handleDelete(i) {
                    this.addedGoods.splice(i, 1);
                    this.handleSum();
                },
                // 添加操作
                handleAdd(i) {
                    let flag = false;
                    this.addedGoods.forEach(item => {
                        if (item.name == this.goods[i].name) {
                            item.count++;
                            flag = true;
                        }
                    })
                    if (!flag) this.addedGoods.push(this.goods[i]);
                },
                clearCart(){
                  this.addedGoods = [];
                },
                shopping(){
                    this.$emit('changeType',1)
                }


	}
}
</script>

<style>

</style>
