<template>
  <div style="width: 90%;margin-left: 5%;margin-bottom: 20px">
    <div style="margin-top: 20px;background-color: #F4F4F4;height: 150px">
      <el-row>
        <el-col :span="8">
          <el-image style="width: 150px;height: 100px;margin-left: 50px" src="http://linkedhi.cn//upload/seller/20210806024757564.png"></el-image>
          <div style="font-size: 16px;margin-left: 80px">洱全药品</div>
        </el-col>
        <el-col :span="8">
          <div class="titleInDiv" style="font-size: 18px">综合评价：5</div>
          <div class="titleInDiv"><i class="el-icon-star-off"></i>评价 ：5</div>
          <div class="titleInDiv"><i class="el-icon-phone-outline"></i>服务 ：5</div>
          <div class="titleInDiv"><i class="el-icon-s-order"></i>履约 ：5</div>
        </el-col>
        <el-col :span="8">
          <div class="titleInDiv" style="text-align: left">退  单  率：0.5%</div>
          <div class="titleInDiv" style="text-align: left">发货时长：5.2小时</div>
          <div class="titleInDiv" style="text-align: left">营业时间：8:00-20:00</div>
          <div class="titleInDiv" style="text-align: left">店铺地址：河北省 保定市 涞水县</div>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 30px;background-color: #F4F4F4">
      <el-row>
        <el-col :span="6" style="border-right: 8px solid white">
          <div>
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwMjQzOTkwLmpwZy93LzM4MC9oLzM4MAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥31.50</div>
            <div class="textInDiv">左益 苯磺酸左氨氯地平片</div>
            <div class="textInDiv" style="margin-left: 120px">5mg*14片</div>
          </div>
        </el-col>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwODM0NzA3LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥30.00</div>
            <div class="textInDiv">施慧达 苯磺酸左氨氯地平片</div>
            <div class="textInDiv" style="margin-left: 120px">2.5mg*7片*2板</div>
          </div>
        </el-col>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMwOTI2MjIyLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥79.00</div>
            <div class="textInDiv">百普乐 培哚普利吲达帕胺片</div>
            <div class="textInDiv" style="margin-left: 120px">20片</div>
          </div>
        </el-col>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMDMxNDQyLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥33.00</div>
            <div class="textInDiv">拜新同 硝苯地平控释片</div>
            <div class="textInDiv" style="margin-left: 120px">30mg*7片</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 20px;background-color: #F4F4F4">
      <el-row>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMTUzMTQzLmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥34.80</div>
            <div class="textInDiv">阿尔马尔 盐酸阿罗洛尔片</div>
            <div class="textInDiv" style="margin-left: 120px">10mg*10片</div>
          </div>
        </el-col>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMjIwODQ3LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥17.00</div>
            <div class="textInDiv">喜来乐 复方杜仲片(杜仲降压片)</div>
            <div class="textInDiv" style="margin-left: 120px">0.32g*90片/瓶</div>
          </div>
        </el-col>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMjU0ODc3LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥36.50</div>
            <div class="textInDiv">瑙珍 复方天麻蜜环糖肽片 0.25g*12片*4板</div>
            <div class="textInDiv" style="margin-left: 120px">20片</div>
          </div>
        </el-col>
        <el-col :span="6" style="border-right: 8px solid white">
          <div @click="goToGoodsDetail" class="shoppingDetail">
            <el-image class="formInImage" src="http://linkedhi.cn/index.php?controller=pic&action=thumb&img=d3X1B6scbc20Fbk3L0zeIewcM8j3EcwdO1DeA72eL8z5I6w2M7jcE5w8O4D6A82cMDMxMzI4MzI5LmpwZy93LzMwMC9oLzMwMAO0O0OO0O0O"></el-image>
            <div class="priceInDiv">￥11.50</div>
            <div class="textInDiv">伊迈格 托拉塞米片</div>
            <div class="textInDiv" style="margin-left: 120px">5mg*14片</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'index',
    data() {
      return {

      }
    },
    methods: {
      goToGoodsDetail(){
        this.$emit('changeType',13)
      }
    }
  }
</script>

<style lang="scss">
  .titleInDiv{
    font-size: 15px;
    margin-top: 10px;
  }

  .formInImage{
    width: 150px;
    height: 150px;
    margin-left: 80px;
    margin-top: 10px;
  }

  .priceInDiv{
    text-align: center;
    margin-left: 80px;
    width: 150px;
    color: #3A3C84;
    font-size: 16px;
  }

  .textInDiv{
    font-size: 14px;
    margin-left: 80px;
    margin-top:10px;
    margin-bottom: 10px;
  }

  .shoppingDetail{
    cursor:pointer;
  }
</style>
